import { useParams } from "react-router-dom";
import FlashSetLoader from "../components/flash-set-loader";
import LearnCards from "./components/learn-cards";
import Options from "../../components/options";

export default function LearnFlashSet() {
    const params = useParams();

    return (
        <FlashSetLoader id={params.id!}>
            <Options>
                <LearnCards />
            </Options>
        </FlashSetLoader>
    )
}