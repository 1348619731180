import { Operation } from "fast-json-patch";
import { processFlashSet } from "./flash-set-modifier-service";
import { FlashSet, ICard } from "../models/flash-set";
import { Topic } from "../sets/models/topic";

export async function getMySets(): Promise<FlashSet[]> {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/sets/my-sets`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
    });

    if(!response.ok) throw new Error();

    return await response.json();
}

export async function createFlashSet(name: string, topics: Topic[]): Promise<FlashSet> {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/sets`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(
            {
                name,
                topics
            }
        )
    });

    if(!response.ok) throw new Error();

    return await response.json();
}

export async function getFlashSet(id: string): Promise<FlashSet> {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/sets/${id}`, {
        method: 'GET',
        credentials: 'include',
        mode: 'cors'
    });

    if(!response.ok) throw new Error();

    var flashSet = await response.json();

    processFlashSet(flashSet);

    return flashSet;
}

export async function patchFlashSet(id: string, patch: Operation[]) {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/sets/${id}`, {
        method: 'PATCH',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'content-type': 'application/json-patch+json'
        },
        body: JSON.stringify(patch)
    });

    if(!response.ok) throw new Error();
    
    await response.json();
}

export async function deleteFlashSet(id: string) {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/sets/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        mode: 'cors'
    });

    if(!response.ok) throw new Error();
}

export async function createCard(question: string): Promise<ICard> {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cards`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            question
        })
    });

    if(!response.ok) throw new Error();
    
    return await response.json();
}

export async function createCardFromPrompt(prompt: string): Promise<ICard> {
    var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/cards`, {
        method: 'POST',
        credentials: 'include',
        mode: 'cors',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            prompt
        })
    });

    if(!response.ok) throw new Error();
    
    return await response.json();
}