import { Button, Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { ICard } from "../../models/flash-set";
import { replaceAt } from "../../common/utils";

export type CardChanged = (card: ICard) => void;

export default function EditCard(props: {card: ICard, onChange: CardChanged}) {
    const [prompt, setPrompt] = useState(props.card.prompt);
    const [keywords, setKeywords] = useState(props.card.keywords);

    const deleteKeyword = (index: number) => {
        const fragments = prompt.split("___");
        const preKeywordFragments = fragments.slice(0, index + 1);
        const preKeywordPrompt = preKeywordFragments.join("___");
        const indexOfKeyword = preKeywordPrompt.length;

        setPrompt(replaceAt(prompt, indexOfKeyword, "___", keywords[index]));

        keywords.splice(index, 1);
        setKeywords([...keywords]);
    };

    const updatePrompt = (index: number, value: string | null) => {
        const fragments = prompt.split("___");

        if(value == null || value == '') {
           value = ' '; // keeping space is very important
        } 

        fragments[index] = value;

        setPrompt(fragments.join("___"));
    };

    useEffect(() => {
        props.onChange({
            ...props.card,
            prompt, 
            keywords
        })
    }, [prompt, keywords]);

    const makeKeyword = (index: number, fragment: string, keywordOffset: number, keyword: string) => {
        const fragments = prompt.split("___");
        
        fragments[index] = replaceAt(fragment, keywordOffset, keyword, "___");

        const newKeywords = [...keywords];
        if(index < newKeywords.length) {
            newKeywords.splice(index, 0, keyword);
        } else {
            newKeywords.push(keyword);
        }

        setPrompt(fragments.join("___"));
        setKeywords(newKeywords)
    }

    return (
        <div className="card-viewer text-large font-bold leading-10" style={{whiteSpace: 'pre-wrap'}}>
            { prompt.split("___").map((fragment, index) => (
                <span key={index}>
                    <EditPromptFragment 
                        fragment={fragment}
                        onPromptUpdated={value => updatePrompt(index, value)}
                        onMakeKeyword={(prompt, keywordOffset, keyword) => makeKeyword(index, prompt, keywordOffset, keyword)}
                    />

                    {index < keywords.length && (
                        <Popover key={`${index}-${keywords[index]}`} showArrow={true} placement="bottom">
                            <PopoverTrigger>
                                <button className="text-success">{keywords[index]}</button>
                            </PopoverTrigger>
                            <PopoverContent>
                                <Button color="danger" onClick={() => deleteKeyword(index)}>Always Visible</Button>
                            </PopoverContent>
                        </Popover>
                    )}
                </span>
            ))}
        </div>
    )
}

type MakeKeywordEvent = (fragment: string, keywordOffset: number, keyword: string) => void;
type FragmentUpdatedEvent = (fragment: string | null) => void;

function EditPromptFragment(props: {fragment: string, onPromptUpdated: FragmentUpdatedEvent, onMakeKeyword: MakeKeywordEvent}) {
    const [prefix, setPrefix] = useState('');
    const [keyword, setKeword] = useState('');
    const [suffix, setSuffix] = useState('');

    const reset = () => {
        setPrefix(props.fragment);
        setKeword('')
        setSuffix('')
    }

    useEffect(() => {
        reset();
    }, [props.fragment])

    const makeKeyword = () => {
        props.onMakeKeyword(`${prefix}${keyword}${suffix}`, prefix.length, keyword);
    }

    const selected = (text: string) => {
        const selection = window.getSelection()!;
        const start = Math.min(selection.anchorOffset, selection.focusOffset);
        const end =  Math.max(selection.anchorOffset, selection.focusOffset);

        const keyword = text.substring(start, end);

        if(keyword.trim().length == 0) {
            reset()
        } else {
            setPrefix(text.substring(0, start))
            setKeword(keyword)
            setSuffix(text.substring(end))
        }
    }

    return (
        <span 
            contentEditable 
            onContextMenu={e => e.preventDefault()}
            onSelect={e => selected(e.currentTarget.innerText)}
            onBlur={e => props.onPromptUpdated(e.currentTarget.innerText)}
        >
            {prefix && <span>{prefix}</span>}

            {keyword && (
                <Popover defaultOpen={true} showArrow={true} onClose={reset} placement="bottom">
                    <PopoverTrigger>
                        <span>{keyword}</span>
                    </PopoverTrigger>
                    <PopoverContent>
                        <Button color="success" onClick={makeKeyword}>Hide Sometimes</Button>
                    </PopoverContent>
                </Popover>
            )}

            {suffix && <span>{suffix}</span>}
        </span>
    )
}