import { ICard, FlashSet, Topic } from "../models/flash-set";
import { getRandomInt, replaceAt } from "./utils";

export function processFlashSet(flashSet: FlashSet) {
    for(var topic of flashSet.topics) {
        for(var card of topic.cards) {    
            setAnswersForCard(card);
        }
    }
}

export function setAnswersForCard(card: ICard) {
    const answers: string[] = [];

    card.currentPrompt = card.prompt;

    if(card.keywords == null || card.keywords.length == 0) {
        card.answers = answers;
        return;
    }

    const numberOfKeyWordSelections = card.keywords.length < 3 ? 1 : 3;

    const selectedKeywordIndexes: number[] = [];
    while(selectedKeywordIndexes.length < numberOfKeyWordSelections) {

        const index = getRandomInt(0, card.keywords.length);

        if(selectedKeywordIndexes.includes(index)) continue;

        selectedKeywordIndexes.push(index);
    }

    
    let index = card.currentPrompt.indexOf("___", 0);
    let keywordIndex = 0;

    do {
        if(selectedKeywordIndexes.includes(keywordIndex)) {
            answers.push(card.keywords[keywordIndex]);
        } else {
            card.currentPrompt = replaceAt(card.currentPrompt, index, "___", card.keywords[keywordIndex]);
        }

        index = card.currentPrompt.indexOf("___", index + 3);
        keywordIndex++;
    } while (index != -1);

    card.answers = answers;
}

export function copyFlashSet(flashSet: FlashSet): FlashSet {
    return {
        ...flashSet,
        topics: flashSet.topics.map(x => {
            return { 
                ...x,
                cards: x.cards.map(copyCard)
            }
        })
    };
}

export function copyCard(q: ICard): ICard {
    return {
        ...q,
        keywords: [...q.keywords]
    }
}

export function getTopicWithCard(flashSet: FlashSet, card: ICard): Topic {
    for(var topic of flashSet.topics) {
        const index = topic.cards.findIndex(x => x.id == card.id);
        if(index != -1) return topic;
    }

    throw new Error();
}