import SupportLink from "../../../common/components/support-link";
import { faQuestionCircle, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";

export default function InfoModal() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <div>
            <Button onClick={onOpen}  color="primary" variant="flat" endContent={<FontAwesomeIcon icon={faQuestionCircle}/>}>Help</Button>
            <Modal backdrop="blur" placement="center" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Help</ModalHeader>

                            <ModalBody>
                                <div>
                                    <div>
                                        Reveal the answer by clicking the card
                                        <Spacer y={3} />
                                        Dynamic Cards change each time you start a set or by clicking the refresh icon
                                        <Spacer y={3} />
                                        You can edit a card by clicking the menu item at the top right
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <SupportLink />
                                <Button color="success" variant="light" size="lg" onPress={onClose} startContent={<FontAwesomeIcon icon={faThumbsUp} />}>
                                    Got It
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}