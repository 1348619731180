import { ICard } from "../../models/flash-set";
import Loading from "../../components/loading";
import { faHammer, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "@nextui-org/input";
import { Button, Spacer } from "@nextui-org/react";
import { useState } from "react";
import { createCard } from "../../common/api";
import { setAnswersForCard } from "../../common/flash-set-modifier-service";
import CardView from "../../sets/components/card-view";

export default function TryItOut() {

    return (
        <div>
            <div id="learn-more" className="text-center">
                <div className="md:leading-10 tracking-tight font-semibold text-2xl lg:text-5xl text-danger">Try It Out</div>
                <Spacer y={1} />
                <div className="text-center text-default-1000 font-semibold text-md lg:text-xl">Turn a Question into a <span className="text-warning">Dynamic Flash Card</span></div>
            </div>

            <Spacer y={10} />

            <TryItOutInteractive />
        </div>
    )

}

function TryItOutInteractive() {
    const [question, setQuestion] = useState('What are Mitochondria?');
    const [card, setCard] = useState<ICard  | null>(null);
    const [loading, setLoading] = useState(false);

    const createFlashCard = async () => {
        try {
            setLoading(true);

            const newCard = await createCard(question);
            setAnswersForCard(newCard);

            setCard(newCard);
        } catch {

        } finally {
            setLoading(false);
        }
    }

    const refreshCard = () => {
        setAnswersForCard(card!);
        setCard({...card!});
    }

    const reset = () => {
        setQuestion('')
        setCard(null);
    }

    if(loading) return <Loading /> // todo use a skeleton loader instead

    if(card) return (
        <div>
            <div className="text-center text-default-500">Reveal the answer by clicking the card</div>

            <Spacer y={5} />

            <CardView card={card} edit={false} dontResetAnswerOnChange onChange={() => {}} />

            <Spacer y={5} />

            <div style={{display: 'flex', justifyContent: 'center', gap: '15px'}}>
                <Button
                    color="secondary"
                    onClick={refreshCard}
                    startContent={<FontAwesomeIcon icon={faRefresh}/>}
                >
                    Change Answers
                </Button>

                <Button
                    onClick={reset}
                >
                    New Card
                </Button>
            </div>
        </div>
    )

    return (
        <div>
            <Input value={question} onValueChange={setQuestion} label="A simple question or phrase"/>
       
            <Spacer y={5} />

            <Button 
                color="primary" 
                isDisabled={question == ''} 
                fullWidth
                startContent={<FontAwesomeIcon icon={faHammer}/>}
                size="lg"
                onClick={createFlashCard}
            >
                Create Dynamic Flash Card
            </Button>
            <div>

            </div>
        </div>
    )
}