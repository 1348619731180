import { useContext, useEffect, useState } from "react";
import { CardIndexChanged, FlashSetContext } from "../../flash-set-context";
import { ICard } from "../../../../models/flash-set";
import { copyCard, setAnswersForCard } from "../../../../common/flash-set-modifier-service";
import { shuffle } from "../../../../common/utils";
import { faChevronLeft, faRotate, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spacer } from "@nextui-org/react";
import CardController from "../../components/card-controller";

export default function StudyCards() {
    const { flashSet, deleteCard, updateCard } = useContext(FlashSetContext);
    const [cards, setCards] = useState<ICard[]>([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    useEffect(() => {
        if(flashSet?.topics == null) return;

        var newCards: ICard[] = [];

        // Copy all cards and be sure to copy sub data so updates are detected
        for(var topic of flashSet.topics) {
            for(var card of topic.cards) {
                newCards.push(copyCard(card));
            }
        }

        shuffle(newCards); 
        setCards(newCards);
    }, [flashSet?.id]);

    useEffect(() => {
        if(currentCardIndex > cards.length) setCurrentCardIndex(currentCardIndex - 1);
    }, [cards])

    const deleteCardHere = async (card: ICard) => {
        await deleteCard(card);
        setCards(cards.filter(x => x.id != card.id));
    }

    const updateCardHere = async (card: ICard) => {
        console.log(card)

        await updateCard(card);

        setAnswersForCard(card);
        const cardIndex = cards.findIndex(x => x.id == card.id);
        cards[cardIndex] = card;
        setCards([...cards]);
    }

    const refreshCardAnswers = () => {
        setAnswersForCard(cards[currentCardIndex]);
        setCards([...cards])
    };

    return (
        <div style={{minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
            <CardController 
                cards={cards} 
                onDeleteCard={deleteCardHere} 
                onUpdateCard={updateCardHere}
                currentCardIndex={currentCardIndex}
            />

            <StudyCardControls
                cards={cards}
                currentCardIndex={currentCardIndex}
                setCurrentCardIndex={setCurrentCardIndex}
                onRefresh={refreshCardAnswers}
            />
        </div>
    );
}


function StudyCardControls({cards, currentCardIndex, setCurrentCardIndex, onRefresh}: {cards: ICard[], currentCardIndex: number, setCurrentCardIndex: CardIndexChanged, onRefresh: any}) {
    const previousCard = () => setCurrentCardIndex(currentCardIndex - 1);
    const nextCard = () => setCurrentCardIndex(currentCardIndex + 1);

    return (
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
            <Button onClick={previousCard} isDisabled={currentCardIndex == 0} isIconOnly>
                <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <div style={{display: currentCardIndex < cards.length ? 'block' : 'none'}}>
                <div style={{textAlign: 'center'}}>
                    {currentCardIndex < cards.length && <small>{currentCardIndex + 1} of {cards.length}</small>}
                </div>
                <Spacer y={2} />
                <Button color="secondary" isIconOnly onClick={onRefresh}>
                    <FontAwesomeIcon icon={faRotate} size="xl" />
                </Button>
            </div>
            <Button onClick={nextCard} isDisabled={currentCardIndex >= cards.length} isIconOnly>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>
        </div>
    )
}