import { Button, Link, Spacer } from "@nextui-org/react";

export default function ErrorBox(props: {onClose: any}) {
    return (
        <div>
            <blockquote className="border px-4 py-3 rounded-xl [&amp;>p]:m-0 border-danger-100 bg-danger-50/20 my-2 text-center">
                <div>
                    Something went wrong
                </div>
                <Spacer y={5} />
                <div>
                    Please try again
                </div>
                <Spacer y={5} />
                <Button onClick={props.onClose} variant="bordered">Ok</Button>
                <Spacer y={5} />
                <Link className="text-small" href="mailto:support@fast-flash.com">Contact Support</Link>
            </blockquote>

        </div>
    )
}