import { useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import { useEffect } from "react";

export default function DeleteCardControl(props: {trigger: number, onDelete: any}) {
    const {isOpen, onOpen, onClose} = useDisclosure();

    const deleteCard = () => {
        props.onDelete();
        onClose();
    }

    useEffect(() => {
        if(props.trigger != 0)
            onOpen();
    }, [props.trigger])

    return (
        <div>
            <Modal backdrop="blur" placement="center" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">Delete Card?</ModalHeader>
                    <ModalBody>
                        <p> 
                        Are you sure you want to delete this card?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" variant="light" onPress={onClose}>
                        No
                        </Button>
                        <Button color="danger" onPress={deleteCard}>
                        Yes
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    )
}