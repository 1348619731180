import { Button, Card, CardBody, Code, Link, Spacer } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faBrain, faClock, faGear, faRightLong, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import Footer from "../common/components/footer";
import TryItOut from "./components/try-it-out";

export default function About() {
    return (
        <div>
            <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <div style={{textAlign: 'center'}}>
                    <div 
                        className="leading-10 md:leading-10 tracking-tight font-semibold text-[2.5rem] lg:text-5xl"
                        style={{textTransform: 'capitalize'}}
                    >
                        Make the most out of your <span className="text-warning">studies</span>
                    </div>

                    <Spacer y={3} />
                    <div className="text-center text-default-500 lg:text-xl">Create flash cards in a flash</div>
                </div>

                <Spacer y={5} />

                <Button size="lg" 
                    color="primary" 
                    endContent={<FontAwesomeIcon icon={faRightLong} />}
                    href="/login"
                    as={Link}
                >
                    Get Started
                </Button>

                <div>
                    <Spacer y={20} />
                    <div style={{display: 'flex', flexDirection: 'column', gap: '10px', width: '100%'}}>
                        <Code color="primary" className="lg:text-xl text-lg"><FontAwesomeIcon icon={faUserCheck} style={{width: '20px'}} /> Easy to Use</Code>
                        <Code color="success" className="lg:text-xl text-lg"><FontAwesomeIcon icon={faClock} style={{width: '20px'}}/> More Study Time</Code>
                        <Code color="secondary" className="lg:text-xl text-lg"><FontAwesomeIcon icon={faBrain} style={{width: '20px'}}/> Better Retention</Code>
                        <Code color="default" className="lg:text-xl text-lg"><FontAwesomeIcon icon={faGear} style={{width: '20px'}}/> Customizable</Code>
                    </div>
                    <Spacer y={5} />
                    <div className="text-center text-default-500 text-md lg:text-xl">Learn More <FontAwesomeIcon icon={faAngleDown} /></div>
                </div>
            </div>

            <TryItOut />

            <Spacer y={40} />

            <div className="text-center">
                <div className="md:leading-10 tracking-tight font-semibold text-2xl lg:text-5xl">Simple and <span className="text-danger">Powerful</span></div>
                <Spacer y={1} />

                <div className="text-center text-default-1000 font-semibold text-md lg:text-xl">
                    Fast Flash is a great way to create a diverse flash card set in seconds
                </div>

                <Spacer y={10} />

                <Card>
                    <CardBody className="text-center">
                        <FontAwesomeIcon className="text-primary" icon={faUserCheck} style={{fontSize: 'xx-large'}} />
                        <Spacer y={5} />
                        <div className="text-default-1000 font-semibold">
                            Fast Flash is developed with an emphasis on simplicity to make your life easier
                        </div>
                    </CardBody>
                </Card>

                <Spacer y={10} />

                <Card>
                    <CardBody className="text-center">
                        <FontAwesomeIcon className="text-success" icon={faClock} style={{fontSize: 'xx-large'}} />
                        <Spacer y={5} />
                        <div className="text-default-1000 font-semibold">
                            Spend less time creating, editing flash cards and more time learning 
                        </div>
                    </CardBody>
                </Card>

                <Spacer y={10} />

                <Card>
                    <CardBody className="text-center">
                        <FontAwesomeIcon className="text-secondary" icon={faBrain} style={{fontSize: 'xx-large'}}/>
                        <Spacer y={5} />
                        <div className="text-default-1000 font-semibold">
                            Fast Flash can help you learn faster and remember the material longer
                        </div>
                    </CardBody>
                </Card>

                <Spacer y={10} />

                <Card>
                    <CardBody className="text-center">
                        <FontAwesomeIcon className="text-default-1000" icon={faGear} style={{fontSize: 'xx-large'}}/>
                        <Spacer y={5} />
                        <div className="text-default-1000 font-semibold">
                            Dynamic Flash Cards are customizable to fit your needs
                        </div>
                        <Spacer y={2} />
                        <div className="text-default-500">You can also bring your own prompt and skip the question form</div>
                    </CardBody>
                </Card>
            </div>

            <Spacer y={10} />

            <Button size="lg" 
                    color="primary" 
                    endContent={<FontAwesomeIcon icon={faRightLong} />}
                    href="/login"
                    as={Link}
                    fullWidth
            >
                Get Started
            </Button>

            <Spacer y={20} />

            <div className="text-center">
                <div className="md:leading-10 tracking-tight font-semibold text-2xl lg:text-5xl text-warning">About Fast Flash</div>
                <Spacer y={1} />

                <div className="text-center text-default-1000 font-semibold text-md lg:text-xl">
                   Fast Flash is developed and managed by me, Terry!
                   
                   <Spacer y={2} />
                    I started Fast Flash as a personal project after watching someone study for exams. They were using a popular flash card service but complained the interface was too complicated and it took too long to create new flash card sets. 

                    <Spacer y={2} />
                    Keeping those concerns in mind, I created Fast Flash. An easy to use flash card service allowing users to create diverse study sets quickly. 
                </div>
            </div>

            <Spacer y={12} />

            <Footer />
        </div>
    )
}