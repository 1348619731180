import { Link, Navbar, NavbarBrand, NavbarContent, NavbarItem } from "@nextui-org/react";
import { useContext } from "react";
import { UserContext } from "../common/user-context";

export default function NavBar() {
    var user = useContext(UserContext);

    return (
        <Navbar>
            <NavbarBrand>
                
                <Link className="font-bold text-inherit" color="foreground" href="/">
                    <img src="/logo.png" style={{width: '20px', marginRight: '10px'}}/> Fast Flash
                </Link>
            </NavbarBrand>

            <NavbarContent justify="end">
                {user != null ? (
                    <NavbarItem>
                        <Link className="font-bold text-inherit" color="foreground" href="/sets">
                            Sets
                        </Link>
                    </NavbarItem>
                ) : (
                    <NavbarItem>
                        <Link className="font-bold text-inherit" color="foreground" href="/login">
                            Login
                        </Link>
                    </NavbarItem>
                )}
                
            </NavbarContent>
        </Navbar>
    )
}