import { Spacer } from "@nextui-org/react";

export default function SectionHeader(props: {title: string}) {
    return (
        <div>
            <Spacer y={5} />
            <h1 className="font-bold text-large">{props.title}</h1>
            <Spacer y={5} />
        </div>
    )
}