import { Button, Spacer } from "@nextui-org/react";
import SectionHeader from "../../common/components/section-header";
import { useContext } from "react";
import { UserContext } from "../../common/user-context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export default function Account() {
    const user = useContext(UserContext);

    return (
        <div>
            <SectionHeader title="Account" />

            <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}>
                <FontAwesomeIcon icon={faUser} />
                {user?.email}
            </div>

            <Spacer y={7} />

            <form action={`${process.env.REACT_APP_API_BASE_URL}/account/logout?returnUrl=${process.env.REACT_APP_WEB_BASE_URL}`} method="post">
                <Button color="danger" type="submit">Logout</Button>
            </form>
        </div>
    )
}