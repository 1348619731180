import { ICard } from "../../../models/flash-set";
import CardView from "../../components/card-view";
import { faSave, faTrash, faPencil, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Autocomplete, AutocompleteItem, Spacer, Button } from "@nextui-org/react";
import { useState } from "react";
import { ICreatedCard } from "../models/created-card";

type CardChangedEvent = (card: ICreatedCard) => void;

export default function CreatedCardView(props: {card: ICreatedCard, topics: string[], onChange: CardChangedEvent, onRefresh: any, onDelete: any}) {
    const [isEdit, setIsEdit] = useState(false);

    const cardChanged = (card: ICard) => {
        props.onChange({
            ...props.card,
            ...card
        });
    }

    const updateTopic = (value: string) => {
        props.onChange({
            ...props.card,
            topic: value
        });
    }

    return (
        <div>
            <Autocomplete
                inputValue={props.card.topic}
                onInputChange={updateTopic}
                placeholder="Enter a topic (optional)"
                description="Topics group cards together"
                allowsCustomValue={true}
                defaultItems={props.topics.map(topic => { 
                    return {
                        label: topic, value: topic
                    }
                })}
                maxLength={100}
            >
                {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
            </Autocomplete>

            <Spacer y={5} />

            <CardView card={props.card} edit={isEdit} onChange={cardChanged} />
            
            <Spacer y={5} />

            {isEdit 
                ? (
                    <div style={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
                        <Button size="lg" color="success" isIconOnly onClick={() => setIsEdit(false)}>
                            <FontAwesomeIcon icon={faSave} />
                        </Button>
                    </div>
                )
                : (
                    <div style={{display: 'flex', justifyContent: 'center', gap: '20px'}}>
                        <Button size="lg" color="danger" isIconOnly onClick={props.onDelete}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                        <Button size="lg" color="secondary" isIconOnly onClick={props.onRefresh}>
                            <FontAwesomeIcon icon={faRefresh} />
                        </Button>
                        <Button size="lg" color="primary" isIconOnly onClick={() => setIsEdit(true)}>
                            <FontAwesomeIcon icon={faPencil} />
                        </Button>
                    </div>
                )
            }
        </div>
    )
}