import { useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button } from "@nextui-org/react";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FlashSet } from "../../models/flash-set";
import { useState } from "react";
import { deleteFlashSet } from "../../common/api";

export default function DeleteFlashSet(props: {set: FlashSet, onDeleted: any}) {
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);

    const deleteSet = async () => {
        try {
            setIsLoading(true);
            onClose();

            await deleteFlashSet(props.set.id);

            props.onDeleted();
        } catch {
            // todo 
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Button color="danger" isIconOnly onClick={onOpen} isLoading={isLoading} isDisabled={isLoading}>
                <FontAwesomeIcon icon={faTrash} size="xl"/>
            </Button>

            <Modal backdrop="blur" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                {(onClose) => (
                    <>
                    <ModalHeader className="flex flex-col gap-1">Delete Set?</ModalHeader>
                    <ModalBody>
                        <p> 
                        Are you sure you want to delete this set?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" variant="light" onPress={onClose}>
                        No
                        </Button>
                        <Button color="danger" onPress={deleteSet}>
                        Yes
                        </Button>
                    </ModalFooter>
                    </>
                )}
                </ModalContent>
            </Modal>
        </div>
    )
}