
import FlashSetLoader from "../components/flash-set-loader";
import { useParams } from "react-router-dom";
import StudyCards from "./components/study-cards";
import Options from "../../components/options";

export default function StudyFlashSet() {
    const params = useParams();

    return (
        <FlashSetLoader id={params.id!}>
            <Options>
                <StudyCards  />
            </Options>
        </FlashSetLoader>
    )
}