import {NextUIProvider} from '@nextui-org/react'
import NavBar from './nav-bar'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from '../page';
import About from '../about/page';
import Login from '../login/page';
import { useEffect, useState } from 'react';
import { User } from '../models/user';
import { UserContext } from '../common/user-context';
import Loading from './loading';
import { getCurrentUser } from '../common/user-service';
import Profile from '../sets/page';
import StudyFlashSet from '../sets/view/study/page';
import NotFound from './not-found';
import PrivacyPolicy from '../privacy/privacy-policy';
import LearnFlashSet from '../sets/view/learn/page';
import CreateFlashSet from '../sets/create/page';

const router = createBrowserRouter([
  {
    path: "/",
    Component: Home,
    errorElement: <NotFound />
  },
  {
    path: '/about',
    Component: About,
  },
  {
    path: '/login',
    Component: Login
  },
  {
    path: '/sets',
    Component: Profile
  },
  {
    path: 'sets/study/:id',
    Component: StudyFlashSet
  },
  {
    path: 'sets/learn/:id',
    Component: LearnFlashSet
  },
  {
    path: '/sets/create',
    Component: CreateFlashSet
  },
  {
    path: '/privacy',
    Component: PrivacyPolicy
  }
]);

export default function Providers() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      getCurrentUser()
        .then(setUser)
        .catch(() => {}) // gonna do nothing when errors out getting user
        .finally(() => setLoading(false));
  }, [])

  if(loading) return (
    <div style={{padding: '20px'}}>
      <Loading />
    </div>
  )

  return (
    <UserContext.Provider value={user}>
      <NextUIProvider style={{maxWidth: '1000px', height: '100vh', margin: 'auto', display: 'flex', flexDirection: 'column'}}>
        <NavBar />
        <div style={{flexGrow: '1', padding: '20px'}}>
          <RouterProvider router={router} />
        </div>
      </NextUIProvider>
    </UserContext.Provider>
  )
}
