import { useEffect, useState } from "react";
import { getFlashSet, patchFlashSet } from "../../../common/api";
import Loading from "../../../components/loading";
import { FlashSet, ICard } from "../../../models/flash-set";
import ErrorBox from "../../../common/components/error-box";
import { copyFlashSet, getTopicWithCard, copyCard } from "../../../common/flash-set-modifier-service";
import { createJsonPatchDocument } from "../../../common/utils";
import { FlashSetContext } from "../flash-set-context";

export default function FlashSetLoader({id, children}: {id: string, children: any}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [flashSet, setFlashSet] = useState<FlashSet | null>(null);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                setFlashSet(await getFlashSet(id));
            } catch {
                setError(true);
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    const updateCard = async (card: ICard) => {
        try {
            setLoading(true);

            const flashSetCpy = copyFlashSet(flashSet!);
            const topic = getTopicWithCard(flashSetCpy, card);
            const cardIndex = topic.cards.findIndex(x => x.id == card.id);
            topic.cards[cardIndex] = copyCard(card);

            await updateFlashSet(flashSetCpy);

            setFlashSet(flashSetCpy);
        } catch (err) {
            setError(true);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteCard = async (card: ICard) => {
        try {
            setLoading(true);

            const flashSetCpy = copyFlashSet(flashSet!);
            const topic = getTopicWithCard(flashSetCpy, card);
            topic.cards = topic.cards.filter(x => x.id != card.id);

            // If no more cards remaining, go ahead and delete this topic too
            if (topic.cards.length == 0) {
                flashSetCpy.topics = flashSetCpy.topics.filter(x => x.id != topic.id);
            }

            await updateFlashSet(flashSetCpy);
           
            setFlashSet(flashSetCpy);
        } catch (err) {
            setError(true);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateFlashSet = async (current: FlashSet) => {
        const patch = createJsonPatchDocument(flashSet, current)
            // Filter out properties that don't exist in api
            .filter(x => !x.path.includes("answers") 
                && !x.path.includes("currentPrompt")
                && !x.path.includes("queueValue"));

        await patchFlashSet(flashSet!.id, patch);
    }

    return (
        <FlashSetContext.Provider value={{flashSet: flashSet, updateCard, deleteCard}}>
            { loading && <Loading />}
            { error && <ErrorBox onClose={() => setError(false)}/>}

            <div style={{display: loading || error ? 'none' : 'block', width: '100%', height: '100%'}}>
                {children}
            </div>
        </FlashSetContext.Provider>
    )
}