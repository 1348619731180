import { Card, CardBody } from "@nextui-org/react";
import { ICard } from "../../models/flash-set";

export default function AnswerCard(props: {card: ICard, showAnswer: boolean}) {
    return (
        <Card className="py-4 card-viewer">
            <CardBody className="overflow-visible py-2">
                <div className="font-bold text-large text-center" style={{whiteSpace: 'pre-wrap'}}>
                    {props.showAnswer ? ( 
                        <div>
                            { props.card.currentPrompt.split("___").map((fragment, index) => (
                                <span key={index}>{fragment}<span className="text-success">{props.card.answers[index]}</span></span>
                            ))}
                        </div>
                        
                    ) : (
                        <p>{props.card.currentPrompt}</p>
                    )}
                </div>
            </CardBody>
        </Card>
    )
}

