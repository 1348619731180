import { Link } from "@nextui-org/react";
import SupportLink from "./support-link";

export default function Footer() {
    return (
        <div style={{padding: '40px', display: 'flex', justifyContent: 'center', gap: '10px'}}>
            <SupportLink />
            <Link href="/privacy">Privacy Policy</Link>
        </div>
    )
}