import SupportLink from "../../../common/components/support-link";
import { faPencil, faQuestionCircle, faRefresh, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionItem, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";

export default function InfoModal() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <div>
            <Button onClick={onOpen} size="lg" color="primary" variant="flat" endContent={<FontAwesomeIcon icon={faQuestionCircle}/>}>Help</Button>
            <Modal backdrop="blur" placement="center" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Help</ModalHeader>

                            <ModalBody>
                                <div>
                                    <Accordion>
                                        <AccordionItem title="Dynamic Cards">
                                            <span className="text-warning">Dynamic Cards</span> are created by asking a question

                                            <Spacer y={3} />
                                            <span className="text-sm text-default-500">Ex: What are Mitochondria?</span>

                                            <Spacer y={3} />
                                            For best results, try to keep cards shorter

                                            <Spacer y={3} />
                                            Click <Button size="sm" color="secondary" isIconOnly><FontAwesomeIcon icon={faRefresh} /></Button> to view a Dynamic Card in action
                                        </AccordionItem>
                                        <AccordionItem title="Keywords">
                                            Keywords are used to create the blank spaces on cards and make them dynamic. 

                                            <Spacer y={3} />
                                            Keywords are <span className="text-success">green</span>                                       

                                            <Spacer y={3} />
                                            3 Keywords are randonly chosen each study session
                                        </AccordionItem>
                                        <AccordionItem title="Edit Keywords">
                                            You can edit cards by clicking <Button size="sm" color="primary" isIconOnly><FontAwesomeIcon icon={faPencil} /></Button>

                                            <Spacer y={3} />
                                            Remove a keyword by clicking it and selecting <span className="text-danger">Always Visible</span> while in edit mode

                                            <Spacer y={3} />
                                            Add keywords by selecting word(s) then clicking <span className="text-success">Hide Sometimes</span> while in edit mode
                                            <br />
                                            <span className="text-default-500 text-sm">Note: Creating a multi-word keyword is not currenlty possible on mobile. Mobile can only make single-word keywords</span>
                                        </AccordionItem>
                                        <AccordionItem title="Topics">
                                            Topics are a way to group flash cards together
                                            <Spacer y={3} />
                                            Topics should be a single word or short phrase
                                            <Spacer y={3} />
                                            Topics are optional 
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <SupportLink />
                                <Button color="success" variant="light" size="lg" onPress={onClose} startContent={<FontAwesomeIcon icon={faThumbsUp} />}>
                                    Got It
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}