import { setAnswersForCard } from "../../../common/flash-set-modifier-service";
import Loading from "../../../components/loading";
import { createCard, createCardFromPrompt } from "../../../common/api";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Textarea } from "@nextui-org/input";
import { useDisclosure, Button, Modal, ModalContent, ModalHeader, ModalBody, Spacer, ModalFooter } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { ICreatedCard } from "../models/created-card";
import ErrorBox from "../../../common/components/error-box";

type CreatedCardEvent = (card: ICreatedCard) => void;

export default function CreateCardModal(props: {disabled: boolean, onCreated: CreatedCardEvent}) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const {isOpen, onOpen, onClose} = useDisclosure();
    const [question, setQuestion] = useState('');
    const [prompt, setPrompt] = useState('');
    const [mode, setMode] = useState('question');

    const createNewCard = async () => {
        try {
            setLoading(true);

            const card = mode == 'question' 
                ? await createCard(question)
                : await createCardFromPrompt(prompt);

            setAnswersForCard(card);

            props.onCreated({
                ...card
            });

            onClose();
        } catch {
            setError(true);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setQuestion('');
        setPrompt('');
    }, [isOpen])

    return (
        <div>
            <Button onClick={onOpen} isDisabled={props.disabled} color="primary" startContent={<FontAwesomeIcon icon={faPlus}/>} fullWidth>Add Card</Button>

            <Modal backdrop="blur" placement="center" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader>Create Card</ModalHeader>
                            <ModalBody>
                                {loading && <Loading />}
                                {error && <ErrorBox onClose={() => setError(false)} />}

                                { (!loading && !error) && (
                                    <>
                                        { mode == 'question' && (
                                            <div>
                                                <Input value={question} maxLength={100} onValueChange={setQuestion} size="lg" placeholder="Ex: What are Mitochondria?" description="A simple question used to generate the dynamic flash card"/>                                  
                                                <Spacer y={3}/>
                                                <Button 
                                                    color="primary"
                                                    onClick={createNewCard} 
                                                    isDisabled={question == ''}
                                                    fullWidth
                                                >
                                                    Create Card
                                                </Button>
                                            </div>
                                        )}

                                        {mode == 'prompt' && (
                                            <div>
                                                <Textarea value={prompt} maxLength={500} onValueChange={setPrompt} size="lg" placeholder="Enter card details"/> 
                                                <Spacer y={3}/>
                                                <Button 
                                                    color="primary"
                                                    onClick={createNewCard} 
                                                    isDisabled={prompt == ''}
                                                    fullWidth
                                                >
                                                    Create Card
                                                </Button>                                
                                            </div>
                                        )}                                        
                                    </>
                                )}
                                </ModalBody>
                            <ModalFooter>
                               {(!loading && !error) && (
                                 <Button 
                                    variant="bordered"
                                    color="secondary"
                                    onClick={() => setMode(mode == 'question' ? 'prompt' : 'question')}
                                >
                                    {mode == 'question' ? 'Switch to Manual Mode' : 'Switch to Question Mode'}
                                </Button>  
                               )}
                            </ModalFooter>
                        </>
                        )}
                </ModalContent>
            </Modal>
        </div>
    )
}