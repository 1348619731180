import { FlashSet, ICard } from "../../models/flash-set";
import { createContext } from "react";

export type CardChanged = (card: ICard) => Promise<void>;
export type CardDeleted = (card: ICard) => Promise<void>;
export type CardIndexChanged = (index: number) => void;

export const FlashSetContext = createContext<{
    flashSet: FlashSet | null,
    updateCard: CardChanged,
    deleteCard: CardDeleted
}>({
    flashSet: null,
    updateCard: () => {return new Promise(() => {})},
    deleteCard: () => {return new Promise(() => {})},
});