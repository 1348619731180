import { faRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spacer, Button, Link } from "@nextui-org/react";

export default function FlashSetEnd() {
    return (
        <div className="text-center">
            <div 
                className="leading-10 md:leading-10 tracking-tight font-semibold text-[2.5rem] lg:text-5xl text-warning"
                style={{textTransform: 'capitalize'}}
            >
                Great Job!
            </div>

            <Spacer y={3} />
            <small className="text-default-500 lg:text-xl">You have completed this set</small>

            <Spacer y={3} />
            <Button size="lg" 
                color="primary" 
                endContent={<FontAwesomeIcon icon={faRightLong} />}
                as={Link}
                href="/sets"
            >
                View My Other Sets
            </Button>
        </div>
    )
}