import SupportLink from "../../common/components/support-link";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";
import { useEffect } from "react";

export default function PasswordModal() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    useEffect(() => {
        if(isOpen) {
            // todo remove eventually
            fetch(`${process.env.REACT_APP_API_BASE_URL}/events/password-attempted`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors'
            })
        }
    }, [isOpen])

    return (
        <div>
            <Button variant="flat" onClick={onOpen}>Use Password</Button>

            <Modal backdrop="blur" placement="center" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Use Password</ModalHeader>

                            <ModalBody>
                                <div> 
                                    Fast Flash currently doesn't support using an email and password for login

                                    <Spacer y={3} />
                                    You can login using Google or Microsoft

                                    <Spacer y={3} />
                                    <span className="text-default-500">Fast Flash will only collect your email from Google or Microsoft</span>                                    
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <SupportLink />
                                <Button color="success" variant="light" size="lg" onPress={onClose} startContent={<FontAwesomeIcon icon={faThumbsUp} />}>
                                    Ok
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}