import { User } from "../models/user";

export async function getCurrentUser(): Promise<User | null> {
    try {
        var response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/account/me`, {
            method: 'GET',
            credentials: 'include',
            mode: 'cors'
        });

        return await response.json();
    } catch {
        console.warn('Could not get user')
        return null;
    }
}