import { ICard } from "../../../../models/flash-set";
import { useContext, useEffect, useState } from "react";
import { FlashSetContext } from "../../flash-set-context";
import CardController from "../../components/card-controller";
import { copyCard, setAnswersForCard } from "../../../../common/flash-set-modifier-service";
import { getRandomInt, shuffle } from "../../../../common/utils";
import { Button, Spacer } from "@nextui-org/react";
import { ILearnCard } from "../models/learn-card";
import { faBrain, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LearnCards() {
    const { flashSet, deleteCard, updateCard } = useContext(FlashSetContext);
    const [cards, setCards] = useState<ILearnCard[]>([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    useEffect(() => {
        if(flashSet?.topics == null) return;

        var newCards: ILearnCard[] = [];

        // Want at least X different variants of a card
        for(var i = 0; i < 2; i++) {
            for(var topic of flashSet.topics) {
                for(var card of topic.cards) {
                    var newCard = copyCard(card);
                    setAnswersForCard(newCard);
                    newCards.push({
                        ...newCard,
                        queueValue: newCards.length * 5 // each card gets incremented by 5 
                    });
                }
            }
        }

        shuffle(newCards);
        setCards(newCards);
    }, [flashSet?.id])

    const deleteCardHere = async (card: ICard) => {
        await deleteCard(card);
        setCards(cards.filter(x => x.id != card.id));
    }

    const updateCardHere = async (card: ICard) => {
        await updateCard(card);

        setAnswersForCard(card);
        const cardIndex = cards.findIndex(x => x.id == card.id);
        cards[cardIndex] = card as ILearnCard;
        setCards([...cards]);
    }

    const onLearned = () => {
        setCurrentCardIndex(currentCardIndex + 1);
    }

    const onNotYet = () => {
        const card = cards[currentCardIndex];
        card.queueValue += getRandomInt(11, 20) // some cards later

        sortCards();
    }

    const sortCards = () => {
        // smaller queue values should appear first 
        const sortedCards = cards.sort((a, b) => a.queueValue - b.queueValue);
        setCards([...sortedCards]);
    }

    return (
        <div style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <CardController 
                cards={cards} 
                onDeleteCard={deleteCardHere} 
                onUpdateCard={updateCardHere}
                currentCardIndex={currentCardIndex}
            />

            { currentCardIndex < cards.length && (
                <div>
                    <div className="text-center">
                        <small>{currentCardIndex} of {cards.length} Learned</small>
                    </div>
                    <Spacer y={2} />
                    <LearnCardControls 
                        onLearned={onLearned}
                        onNotYet={onNotYet}
                    />
                </div>
            )}
        </div>
    )
}

function LearnCardControls(
    {onLearned, onNotYet} 
    : 
    {onLearned: any, onNotYet: any})
{
    return (
        <div style={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
            <Button color="secondary" onClick={onLearned} style={{flexGrow: '1'}} startContent={<FontAwesomeIcon icon={faBrain} />}>Got It</Button>
            <Button color="danger" onClick={onNotYet} style={{flexGrow: '1'}} startContent={<FontAwesomeIcon icon={faClock}/>}>Again</Button>
        </div>
    )
}