import { faPencil, faXmark, faSave, faTrash, faBars } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown, DropdownTrigger, Button, DropdownMenu, DropdownItem, Switch } from "@nextui-org/react"
import DeleteCardControl from "./delete-card-modal"
import { Key, useContext, useState } from "react"
import InfoModal from "./info-modal"
import EditModal from "./edit-modal"
import { OptionsContext } from "../../options-context"

export default function CardControls(props: {isEdit: boolean, onEdit: any, onDelete: any, onCancel: any, onSave: any}) {
    if(props.isEdit) return (
        <EditCardControls
            onCancel={props.onCancel}
            onSave={props.onSave}
        />
    )

    return <ViewCardControls 
        onEdit={props.onEdit}
        onDelete={props.onDelete}
    />
}

function ViewCardControls(props: {onEdit: any, onDelete: any}) {
    const [deleteModalTrigger, setDeleteModalTrigger] = useState(0);   
    const { showAnswerBelow, setShowAnswerBelow } = useContext(OptionsContext); 

    const onAction = (key: Key) => {
        if (key == 'edit') {
            props.onEdit();
        } else if (key == 'delete') {
            setDeleteModalTrigger(deleteModalTrigger + 1);
        }
    } 

    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <InfoModal />
            <Dropdown>
                <DropdownTrigger>
                    <Button isIconOnly variant="flat">
                        <FontAwesomeIcon icon={faBars} />
                    </Button>
                </DropdownTrigger>
                <DropdownMenu onAction={onAction}>
                    <DropdownItem key='edit'>
                        <Button color="primary" startContent={<FontAwesomeIcon icon={faPencil} />} fullWidth>
                            Edit
                        </Button>
                    </DropdownItem>
                    <DropdownItem key='delete'>
                        <Button color="danger" startContent={<FontAwesomeIcon icon={faTrash} />} fullWidth>
                            Delete
                        </Button>
                    </DropdownItem>
                    <DropdownItem key=''>
                        <Switch isSelected={showAnswerBelow} onValueChange={setShowAnswerBelow}>Show Answer Below</Switch>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

            <DeleteCardControl trigger={deleteModalTrigger} onDelete={props.onDelete} />
        </div>
    )
}

function EditCardControls(props: {onCancel: any, onSave: any}) {
    return (
        <div style={{display: 'flex', gap: '10px'}}>
            <EditModal />
            <Button color="danger" isIconOnly onClick={props.onCancel}>
                <FontAwesomeIcon icon={faXmark} />
            </Button>
            <Button color="success" isIconOnly onClick={props.onSave}>
                <FontAwesomeIcon icon={faSave} />
            </Button>
        </div>
    )
}