import { useEffect, useState } from "react"
import { ICreatedCard } from "./models/created-card"
import { Accordion, AccordionItem, Button, Input, Spacer } from "@nextui-org/react";
import { createFlashSet } from "../../common/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { setAnswersForCard } from "../../common/flash-set-modifier-service";
import InfoModal from "./components/info-modal";
import { Topic } from "../models/topic";
import CreateCardModal from "./components/create-card-modal";
import CreatedCardView from "./components/created-card-view";
import Loading from "../../components/loading";
import ErrorBox from "../../common/components/error-box";

export default function CreateFlashSet() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [name, setName] = useState('');
    const [cards, setCards] = useState<ICreatedCard[]>([]);
    const [topics, setTopics] = useState<string[]>([]);
    const [selectedCardKeys, setSelectedCardKeys] = useState<any>(new Set([]));


    const addCard = (card: ICreatedCard) => {
        setCards([...cards, card]);
        var set = new Set([card.id]);
        setSelectedCardKeys(set);
    }

    const updateCard = (card: ICreatedCard) => {
        const index = cards.findIndex(x => x.id == card.id);
        setAnswersForCard(card);
        cards[index] = card;
        setCards([...cards]);
    }

    const deleteCard = (card: ICreatedCard) => {
        setCards(cards.filter(x => x.id != card.id));
    }

    const refreshCard = (card: ICreatedCard) => {
        setAnswersForCard(card);
        updateCard(card);
    }

    const create = async () => {
        try {
            setLoading(true);

            const topics: Topic[] = [];

            for(var card of cards) {
                const cardTopic = card.topic || 'My Topic';
                let indexOfTopic = topics.findIndex(x => x.name == cardTopic);
                if(indexOfTopic == -1) {
                    indexOfTopic = topics.length;
                    topics.push({name: cardTopic, cards: []})
                }

                topics[indexOfTopic].cards.push(card);
            }

            await createFlashSet(name, topics);

            window.location.replace('/sets');
        } catch {
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        const allTopics: string[] = cards.map(x => x.topic || '').filter(x => x != '');
        const uniqueTopics = allTopics.filter((x, index) => allTopics.indexOf(x) == index);
        setTopics(uniqueTopics);
    }, [cards])

    if (loading) return (
        <div>
            <Loading />
        </div>
    )

    if (error) return <ErrorBox onClose={() => setError(false)} />

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'end'}}>
                <InfoModal />
            </div>

            <Spacer y={5} />

            <Input 
                value={name} 
                onValueChange={setName} 
                label="Name" 
                description="Name for this flash set" 
                placeholder="My Set"
                maxLength={100}
            />

            <Spacer y={5} />

            {cards.length > 0 && (
                <Accordion 
                    variant="bordered"
                    selectedKeys={selectedCardKeys}
                    onSelectionChange={setSelectedCardKeys}
                >
                    {cards.map(card => (
                        <AccordionItem key={card.id!} title={card.query}>
                            <CreatedCardView 
                                card={card}
                                topics={topics}
                                onChange={updateCard} 
                                onRefresh={() => refreshCard(card)}
                                onDelete={() => deleteCard(card)}
                            />
                            <Spacer y={3} />
                        </AccordionItem>
                    ))}
                </Accordion>
            )}

            <Spacer y={10} />

            <CreateCardModal disabled={cards.length >= 50} onCreated={addCard} />

            <Spacer y={10} />

            <Button 
                onClick={create} 
                isDisabled={cards.length == 0 || name.length == 0}
                color="success"
                startContent={<FontAwesomeIcon icon={faHammer} />}
                fullWidth
            >
                Create {cards.length} Flash Cards
            </Button>
        </div>
    )
}