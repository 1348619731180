import { Divider, Spacer } from "@nextui-org/react";
import Account from "./components/account";
import Footer from "../common/components/footer";
import FlashSets from "./components/flash-sets";

export default function Profile() {
  return (
    <div>
      <FlashSets />

      <Spacer y={5} />
      <Divider />
      <Spacer y={5} />

      <Account />

      <Spacer y={5} />
      <Footer />
    </div>
  )
}