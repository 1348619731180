import { useEffect, useState } from "react";
import { FlashSet } from "../../models/flash-set";
import { Button, Card, CardFooter, CardHeader, Link, Spacer } from "@nextui-org/react";
import { faBook, faBrain, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SectionHeader from "../../common/components/section-header";
import DeleteFlashSet from "./delete-flash-set";
import Loading from "../../components/loading";
import { getMySets } from "../../common/api";


export default function FlashSets() {
    const [flashSets, setFlashSets] = useState<FlashSet[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getMySets()
          .then(setFlashSets)
          .finally(() => setIsLoading(false));
      }, [])

    const flashSetDeleted = (set: FlashSet) => setFlashSets([...flashSets.filter(x => x.id != set.id)]);

    if(isLoading) return <Loading />;

    return (
        <div>
            <SectionHeader title="Flash Sets" />

            <Button 
                color="success" 
                startContent={<FontAwesomeIcon icon={faPlus} />}
                href={`sets/create`}
                as={Link}
            >
                New
            </Button>
            <Spacer y={3} />
            <div style={{display: 'flex', flexWrap: 'wrap', gap: '15px'}}>
                {flashSets.map(flashSet => (
                    <Card key={flashSet.id}>
                        <CardHeader className="font-bold text-large">
                            {flashSet.name}
                        </CardHeader>
                        <CardFooter>
                            <Button 
                                color="primary" 
                                startContent={<FontAwesomeIcon icon={faBook} />}
                                href={`sets/study/${flashSet.id}`}
                                as={Link}
                            >
                                Study
                            </Button>


                            <Spacer x={2} />
                            <Button 
                                color="secondary" 
                                startContent={<FontAwesomeIcon icon={faBrain} />}
                                href={`sets/learn/${flashSet.id}`}
                                as={Link}
                            >
                                Learn
                            </Button>

                            <Spacer x={2} />

                            <DeleteFlashSet set={flashSet} onDeleted={() => flashSetDeleted(flashSet)} />
                        </CardFooter>
                    </Card>
                ))}
            </div>
        </div>
    )
}