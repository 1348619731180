import SupportLink from "../../../common/components/support-link";
import { faQuestionCircle, faSmile, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Spacer, useDisclosure } from "@nextui-org/react";

export default function EditModal() {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
        <div>
            <Button onClick={onOpen}  color="primary" variant="flat" endContent={<FontAwesomeIcon icon={faQuestionCircle}/>}>Help</Button>
            <Modal backdrop="blur" placement="center" isOpen={isOpen} onClose={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader>Help</ModalHeader>

                            <ModalBody>
                                <div>
                                    <div>
                                        Dynamic cards use <span className="font-semibold">keywords</span> to selectively and randomly make the blank spaces you see
                                        <Spacer y={10} />

                                        <div className="font-bold text-xl">Remove Keyword</div>
                                        <Spacer y={3} />
                                        Remove keywords by clicking the green text and selecting <span className="text-danger">Always Visible</span>
                                    
                                        <Spacer y={10} />

                                        <div className="font-bold text-xl">Add Keyword</div>
                                        <Spacer y={3} />
                                        Add keywords by selecting word(s) then clicking <span className="text-success">Hide Sometimes</span>
                                        <br />
                                        <span className="text-default-500 text-sm">Note: Creating a multi-word keyword is not currenlty possible on mobile. Mobile can only make single-word keywords</span>
                                    
                                        <Spacer y={10} />

                                        <div className="font-bold text-xl">Edit Text</div>
                                        <Spacer y={3} />
                                        Click text area and type what you want <FontAwesomeIcon icon={faSmile} />

                                        <Spacer y={10} />

                                        <div className="font-bold text-xl">Having Problems?</div>
                                        <Spacer y={3} />
                                        Please <SupportLink /> to help with problems you encounter
                                    </div>
                                </div>
                            </ModalBody>

                            <ModalFooter>
                                <Button color="success" variant="light" size="lg" onPress={onClose} startContent={<FontAwesomeIcon icon={faThumbsUp} />}>
                                    Got It
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </div>
    )
}