import { copyCard } from "../../../common/flash-set-modifier-service";
import { useState, useEffect, useContext } from "react";
import { ICard } from "../../../models/flash-set";
import { CardChanged, CardDeleted, FlashSetContext } from "../flash-set-context";
import CardView from "../../components/card-view";
import FlashSetEnd from "./flash-set-end";
import CardControls from "./card-controls";


export default function CardController(
    {cards, currentCardIndex, onDeleteCard, onUpdateCard}
    : 
    {cards: ICard[], currentCardIndex: number, onDeleteCard: CardChanged, onUpdateCard: CardDeleted})
{
    const { flashSet } = useContext(FlashSetContext);
    const [currentCard, setCurrentCard] = useState<ICard | null>(cards[currentCardIndex]);
    const [isEdit, setIsEdit] = useState(false);

    const deleteCard = () => onDeleteCard(currentCard!);
    const saveCard = () => onUpdateCard(currentCard!);
    const updateCard = (card: ICard) => setCurrentCard(copyCard(card));
    const resetCard = () => {
        setCurrentCard(cards[currentCardIndex]);
        setIsEdit(false);
    };

    useEffect(() => {
        setIsEdit(false);
        const card = cards[currentCardIndex];
        setCurrentCard(card == null ? null : copyCard(card));
    }, [cards, currentCardIndex]);
    
    return (
        <div style={{width: '100%', flexGrow: '1', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div className="font-semibold text-xl text-warning" style={{overflow: 'hidden', marginRight: '10px'}}>
                    {flashSet?.name}
                </div>
                {currentCard && 
                    <CardControls 
                        isEdit={isEdit} 
                        onEdit={() => setIsEdit(true)}
                        onDelete={deleteCard}
                        onCancel={resetCard}
                        onSave={saveCard}
                    />
                }
            </div>

            <div style={{flexGrow: '1', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                { currentCard == null
                    ? <FlashSetEnd />
                    : <CardView card={currentCard} edit={isEdit} onChange={updateCard} />
                }
            </div>
        </div>
    )
}