import { useEffect, useState } from "react";
import { OptionsContext } from "../options-context";
import { getCookie, setCookie } from "../../common/utils";


export default function Options(props: {children: any}) {
    const [showAnswerBelow, setShowAnswerBelow] = useState(getCookie('showAnswerBelow') == 'true');

    useEffect(() => {
        setCookie('showAnswerBelow', showAnswerBelow.toString())
    }, [showAnswerBelow])

    return (
        <OptionsContext.Provider
            value={{
                showAnswerBelow,
                setShowAnswerBelow
            }}
        >
            {props.children}
        </OptionsContext.Provider>
    )
}