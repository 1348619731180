import { ICard } from "../../models/flash-set";
import { useState, useEffect, useContext } from "react";
import AnswerCard from "./answer-card";
import EditCard, { CardChanged } from "./edit-card";
import { OptionsContext } from "../options-context";
import { Spacer } from "@nextui-org/react";

export default function CardView(props: {
    card: ICard, 
    edit: boolean, 
    onChange: CardChanged,
    dontResetAnswerOnChange?: boolean
}) {
    const [showAnswer, setShowAnswer] = useState(false);
    const { showAnswerBelow } = useContext(OptionsContext);

    useEffect(() => {
        if(!props.dontResetAnswerOnChange) setShowAnswer(false);
    }, [props.card]);

    useEffect(() => {
        // todo remove eventually
        if(props.edit) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/events/edit-card`, {
                method: 'POST',
                credentials: 'include',
                mode: 'cors'
            })
        }
    }, [props.edit])

    if(props.edit) return <EditCard card={props.card} onChange={props.onChange} />
    return (
        <div style={{position: 'relative'}} onClick={() => setShowAnswer(!showAnswer)}>
            <AnswerCard card={props.card} showAnswer={!showAnswerBelow && showAnswer} />

            {showAnswer && showAnswerBelow && (
                <div style={{position: 'absolute', width: '100%'}} className="text-success font-semibold text-lg text-center">
                    <Spacer y={5} />
                    {props.card.answers.join(', ')}
                </div>
            )}
        </div>
    )
}